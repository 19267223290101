import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './MainApp';
// import MainApp from './MainApp';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/authContext';
import { ModalProvider } from './context/modalContext';
import { ConfigProvider } from 'antd';
import { ProjectProvider } from './context/projectContext';
import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#E76F51',
            },
          }}
        >
          <AuthProvider>
            <ModalProvider>
              <ProjectProvider>
                <App />
              </ProjectProvider>
            </ModalProvider>
          </AuthProvider>
        </ConfigProvider>
      </PostHogProvider>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
