import { getAccount, getSubscription } from '../services/api.service';
import {
  clearAccessToken,
  getAccessToken,
  getUserFromLocalstorage,
  saveUserToLocalstorage,
} from '../services/localstorage';
import { createContext, useState, useContext, useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

const AuthContext = createContext({
  user: undefined,
  setUser: () => {},
  updateUser: () => {},
  userLoaded: false,
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [subscription, setSubscription] = useState(undefined);
  const [usage, setUsage] = useState(undefined);
  const [userLoaded, setUserLoaded] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    const token = getAccessToken();
    if (token) {
      const user = getUserFromLocalstorage();
      setUser(user);
      posthog?.identify(user._id, {
        email: user.email,
        name: user.name,
      });
      getAccount()
        .then((response) => {
          console.log('response', response);
          getSubscription().then(({ data: response }) => {
            setSubscription(response.activeSubscription);
            setUsage(response.consumedTokens);
          }).catch((error) => {
            console.log('Subscription not found', error);
          });
        })
        .catch((error) => {
          if (error.statusCode === 403) {
            clearAccessToken();
            // clearOrganizationAccessToken();
            window.location.href = '/auth/login';
          }
          // window.location.href = '/login';
        });
      // window.location.href = '/';
    } else {
      setUser(null);
      // window.location.href = '/login';
    }
    setUserLoaded(true);
  }, [posthog]);

  const updateUser = (user) => {
    setUser(user);
    saveUserToLocalstorage(user);
  };

  const getFirstName = () => {
    if (user && user.name) {
      return user.name.split(' ')[0];
    }
    return '';
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        subscription,
        usage,
        updateUser,
        userLoaded,
        getFirstName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
