import axios from './axios';

export const loginApi = (body) => {
  return axios.post('/login', body).then((response) => {
    return response.data;
  });
};

export const signupApi = (body) => {
  return axios.post('/signup', body).then((response) => {
    return response.data;
  });
};

export const socialLoginApi = (body) => {
  return axios.post('/social-login', body).then((response) => {
    return response.data;
  });
};

export const verifyEmail = ({ id, otp }) => {
  return axios.post('/verify', { id, otp }).then((response) => {
    return response.data;
  });
};

export const onboardingCreateAccount = ({ projectId, name, email, password, phone }) => {
  return axios.post('/onboarding/signup', { projectId, name, email, password, phone }).then(({ data: response }) => {
    return response.data;
  });
};

export const onboardingAddProjectApi = (body) => {
  return axios.post('/onboarding/create', body).then(({ data: response }) => {
    return response.data;
  });
};

export const onboardingValidateUrlApi = ({ website }) => {
  return axios.post('/onboarding/validate', { website }, {
    timeout: 10000
  }).then(({ data: response }) => {
    return response.data;
  });
};

export const updateUserInfoApi = (userInfo) => {
  return axios.post('/onboarding/user-info', userInfo).then(({ data: response }) => {
    return response.data;
  });
};

export const onboardingFetchBusinessInfoApi = ({ website }) => {
  return axios.post('/onboarding/fetch-business-info', { website }).then(({ data: response }) => {
    return response.data;
  });
};

export const onboardingUpdateProjectApi = ({ projectId, update }) => {
  return axios.post('/onboarding/update-project', { projectId, ...update }).then(({ data: response }) => {
    return response.data;
  });
};

export const onboardingFetchContentThemesApi = ({ projectId }) => {
  return axios.post('/onboarding/fetch-themes', { projectId }).then(({ data: response }) => {
    return response.data;
  });
};

export const onboardingCreateContentStrategyApi = ({ projectId, keyword }) => {
  return axios.post('/onboarding/create-cs', { projectId, keyword }).then(({ data: response }) => {
    return response.data;
  });
};

export const onboardingCreateBulkTopicsApi = ({ projectId, topics }) => {
  return axios.post('/onboarding/topics-bulk-add', { projectId, topics }).then(({ data: response }) => {
    return response.data;
  });
};

export const forgotPasswordApi = async (body) => {
  return axios.post('/forget-password', body).then((response) => {
    return response.data;
  });
};

export const resetPasswordApi = async (body) => {
  return axios.post('/reset-password', body).then((response) => {
    return response.data;
  });
};

export const updateAccount = async (body) => {
  return axios.put('/update-account', body).then((response) => {
    return response.data;
  });
};

export const getAccount = async () => {
  return axios.get('/account').then((response) => {
    return response.data;
  });
};

export const updatePassword = async (body) => {
  return axios.put('/update-password', body).then((response) => {
    return response.data;
  });
};

export const getProjectsApi = async () => {
  return axios.get('/projects').then(({ data: response }) => {
    return response.data;
  });
};

export const createProjectApi = async (body) => {
  return axios.post('/projects', body).then((response) => {
    return response.data;
  });
};

export const getProjectApi = async (projectId) => {
  return axios.get('/projects/' + projectId).then((response) => {
    return response.data;
  });
};

export const updateProjectApi = async ({ projectId, update }) => {
  return axios.put('/projects/' + projectId, update).then((response) => {
    return response.data;
  });
};

export const getProjectAccessTokenApi = async (projectId) => {
  return axios.get('/projects/access-token/' + projectId).then((response) => {
    return response.data;
  });
};
export const validateProjectAccessTokenApi = async (projectId) => {
  return axios.get('/projects/access-token/validate').then((response) => {
    return response.data;
  });
};

export const createTopicApi = async (body) => {
  return axios.post('/topics', body).then((response) => {
    return response.data;
  });
};

export const createMultipleTopicsApi = async (topics) => {
  try {
    for (let i = 0; i < topics.length; i++) {
      const topic = topics[i];
      await axios
        .post('/topics', {
          title: topic.title,
          description: topic.description,
          keywords: topic?.keywords?.join(','),
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
    return true;
  } catch (error) {
    console.log('error', error);
  }
};

export const getTopicsApi = async ({ status }) => {
  return axios
    .get('/topics', {
      params: {
        status,
      },
    })
    .then(({ data: response }) => {
      return response.data;
    });
};
export const addToQueueApi = async ({ topicId }) => {
  return axios
    .post('/topics/add-to-queue', {
      topicId
    })
    .then(({ data: response }) => {
      return response.data;
    });
};

export const getCampaignsApi = async () => {
  return axios.get('/campaigns').then(({ data: response }) => {
    return response.data;
  });
};

export const getCampaignApi = async (campaignId) => {
  return axios.get('/campaigns/' + campaignId).then(({ data: response }) => {
    return response.data;
  });
};

export const createCampaignApi = async (body) => {
  return axios.post('/campaigns', body).then((response) => {
    return response.data;
  });
};

export const updateCampaignApi = async (campaignId, body) => {
  return axios
    .put('/campaigns/' + campaignId, body)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const deleteCampaignApi = async (campaignId) => {
  return axios.delete('/campaigns/' + campaignId).then((response) => {
    return response.data;
  });
};

export const getTopicApi = async (topicId) => {
  return axios.get('/topics/' + topicId).then(({ data: response }) => {
    return response.data;
  });
};

export const createBulkTopicApi = async (topics) => {
  return axios.post('/topics/bulk-create', { topics }).then((response) => {
    return response.data;
  });
};

export const updateTopicApi = async ({ topicId, update }) => {
  return axios.put('/topics/' + topicId, update).then((response) => {
    return response.data;
  });
};

export const deleteTopicApi = async (topicId) => {
  return axios.delete('/topics/' + topicId).then((response) => {
    return response.data;
  });
};

export const bulkDeleteTopicApi = async ({ topics }) => {
  return axios.post('/topics/bulk-delete', {
    topics
  }).then((response) => {
    return response.data;
  });
};

export const getSERPResultsAPI = async (contentId) => {
  return axios.get(`/content/${contentId}/serp`).then(({ data: response }) => {
    return response.data;
  });
};

export const getSERPLinksAPI = async (contentId) => {
  return axios.get(`/content/${contentId}/links`).then(({ data: response }) => {
    return response.data;
  });
};

export const rewriteContentAPI = async ({ contentId, instructions, originalContent }) => {
  return axios.post(`/content/${contentId}/rewrite`, {
    instructions,
    originalContent
  }).then(({ data: response }) => {
    return response.data;
  });
};

export const createTopicSerpBasedOutline = async ({ topicId, query }) => {
  return axios.post(`/topics/${topicId}/create-outline`, {
    query
  }).then((response) => {
    return response.data;
  });
};

export const generateTopicalAuthority = async ({ keywords }) => {
  return axios
    .post('/ai/build-ta', {
      keywords,
    })
    .then((response) => {
      return response.data;
    });
};

export const generatePostTopics = async ({ keywords }) => {
  return axios
    .post('/ai/generate-post-topics', {
      keywords,
    })
    .then((response) => {
      return response.data;
    });
};

export const generateTopicsApi = async ({ projectId, total }) => {
  return axios
    .post('/ai/generate-topics', {
      projectId,
      total,
    })
    .then((response) => {
      return response.data;
    });
};

export const generateOnlyTopicsApi = async ({ projectId, total }) => {
  return axios
    .post('/ai/generate-only-topics', {
      projectId,
    })
    .then((response) => {
      return response.data;
    });
};

export const writeArticleApi = async ({ projectId, topicId }) => {
  return axios
    .post('/ai/write-article', {
      projectId,
      topicId,
    })
    .then((response) => {
      return response.data;
    });
};

export const writeContentApi = async ({ projectId, topicId }) => {
  return axios
    .post('/ai/write-content', {
      projectId,
      topicId,
    })
    .then((response) => {
      return response.data;
    });
};

export const generateKeywordsApi = async ({
  difficulty,
  keyword,
  impact,
  total,
}) => {
  return axios
    .post('/ai/generate-keywords', { difficulty, keyword, impact, total })
    .then((response) => {
      return response.data;
    });
};

export const generateStrategyApi = async ({
  duration,
  frequency,
  goals,
  audience,
}) => {
  return axios
    .post('/ai/generate-content-strategy', {
      duration,
      frequency,
      goals,
      audience,
    })
    .then((response) => {
      return response.data;
    });
};

export const generateTopicsFromKeywordsApi = async ({ keywords }) => {
  return axios.post('/ai/keywords-to-topics', { keywords }).then((response) => {
    return response.data;
  });
};

export const expandExcerptApi = async ({ excerpt }) => {
  return axios.post('/ai/expand-excerpt', { excerpt }).then((response) => {
    return response.data;
  });
};

export const rewriteContentApi = async ({ content, instructions }) => {
  return axios
    .post('/ai/rewrite-content', { content, instructions })
    .then((response) => {
      return response.data;
    });
};

export const fetchBusinessInfoApi = async ({ website }) => {
  return axios.post('/ai/fetch-business-info', { website }).then((response) => {
    return response.data;
  });
};

export const generateOutlineApi = async ({ topicId, length }) => {
  return axios
    .post('/ai/generate-outline', { topicId, length })
    .then((response) => {
      return response.data;
    });
};

export const generateOutlineVersionApi = async ({ topicId, length, format }) => {
  return axios
    .post('/ai/generate-outline-versions', { topicId, length, format })
    .then((response) => {
      return response.data;
    });
};

export const generateOutlineSerpTopicsApi = async ({ topicId, selectedTopics, length }) => {
  return axios
    .post('/ai/generate-outline-serp-topics', { topicId, selectedTopics, length })
    .then((response) => {
      return response.data;
    });
};

export const getCurrentTrends = async ({ geo, date}) => {
  return axios
    .post('/ai/current-trends', { geo, date })
    .then((response) => {
      return response.data;
    });
};

export const getContentsApi = async ({ status }) => {
  return axios
    .get('/content', {
      params: {
        status,
      },
    })
    .then(({ data: response }) => {
      return response.data;
    });
};

export const createContentApi = async (body) => {
  return axios.post('/content/', body).then(({ data: response }) => {
    return response.data;
  });
};

export const createContentFromIdeaApi = async (body) => {
  return axios.post('/content/create-from-ideas', body).then(({ data: response }) => {
    return response.data;
  });
};

export const addPostToQueueApi = async ({ contentId }) => {
  return axios.post('/content/add-to-queue', {
    contentId
  }).then(({ data: response }) => {
    return response.data;
  });
};

export const fetchSearchPagesApi = async ({ contentId, skipWriting, customInstructions }) => {
  return axios.post('/content/fetch-search-pages', {
    contentId,
  }).then(({ data: response }) => {
    return response.data;
  });
};

export const writeFinalPostCompleteApi = async ({ contentId, skipWriting, customInstructions, postTitle }) => {
  return axios.post('/content/write-final-post', {
    contentId,
    skipWriting,
    customInstructions,
    postTitle
  }).then(({ data: response }) => {
    return response.data;
  });
};

export const writeCompletePostApi = async ({ contentId, duplicate }) => {
  return axios.post('/content/write-complete-post', {
    contentId,
    duplicate
  }).then(({ data: response }) => {
    return response.data;
  });
};

export const getContentWithUIDApi = async (contentId) => {
  return axios.get('/content/uid/' + contentId).then(({ data: response }) => {
    return response.data;
  });
};

export const getContentApi = async (contentId) => {
  return axios.get('/content/' + contentId).then(({ data: response }) => {
    return response.data;
  });
};

export const updateContentApi = async (contentId, body) => {
  return axios.put('/content/' + contentId, body).then(({ data: response }) => {
    return response.data;
  });
};

export const deleteContentApi = async (contentId) => {
  return axios.delete('/content/' + contentId).then((response) => {
    return response.data;
  });
};

export const createSERPsAPI = async (contentId, body) => {
  return axios.post('/content/' + contentId + '/create-serp', body).then(({ data: response }) => {
    return response.data;
  });
};

export const fetchContentKeywordsApi = async (contentId, body) => {
  return axios.post('/content/' + contentId + '/create-keywords', body).then(({ data: response }) => {
    return response.data;
  });
};

export const createAIOutlineAPI = async (contentId, body) => {
  return axios.post('/content/' + contentId + '/outline', body).then(({ data: response }) => {
    return response.data;
  });
};

export const publishArticleApi = async ({
  contentId,
  integrationId,
  scheduleFor,
}) => {
  return axios
    .post('/content/publish', {
      contentId,
      integrationId,
      scheduleFor,
    })
    .then((response) => {
      return response.data;
    });
};

export const createIntegrationApi = async (body) => {
  return axios.post('/integrations', body).then((response) => {
    return response.data;
  });
};

export const updateIntegrationApi = async ({ id, update }) => {
  return axios.put('/integrations/' + id, update).then((response) => {
    return response.data;
  });
};

export const getIntegrationsApi = async () => {
  return axios.get('/integrations').then(({ data: response }) => {
    return response.data;
  });
};

export const getIntegrationApi = async (integrationId) => {
  return axios
    .get('/integrations/' + integrationId)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const deleteIntegrationApi = async (integrationId) => {
  return axios.delete('/integrations/' + integrationId).then((response) => {
    return response.data;
  });
};

export const validateIntegrationApi = async ({ integrationType, data }) => {
  return axios.post('/integrations/validate', {
    integrationType,
    data,
  }).then(({ data: response }) => {
    return response.data;
  });
};

export const createIntegrationContentApi = async (body) => {
  return axios.post('/integration-content', body).then((response) => {
    return response.data;
  });
};

export const createKeywordApi = async (body) => {
  return axios.post('/keywords', body).then((response) => {
    return response.data;
  });
};

export const getKeywordsApi = async () => {
  return axios.get('/keywords').then(({ data: response }) => {
    return response.data;
  });
};

export const getKeywordApi = async (keywordId) => {
  return axios.get('/keywords/' + keywordId).then(({ data: response }) => {
    return response.data;
  });
};

export const updateKeywordApi = async (keywordId, body) => {
  return axios
    .put('/keywords/' + keywordId, body)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const deleteKeywordApi = async (keywordId) => {
  return axios.delete('/keywords/' + keywordId).then((response) => {
    return response.data;
  });
};

export const deleteWorkspaceByProjectId = async () => {
  return axios.delete('/projects/delete-project').then((response) => {
    return response.data;
  });
};

export const getDashboardStatsApi = async () => {
  return axios.get('/analytics/dashboard').then(({ data: response }) => {
    return response.data;
  });
};

export const getContentStrategyApi = async ({ status }) => {
  return axios
    .get('/content-strategy', {
      params: {
        status,
      },
    })
    .then(({ data: response }) => {
      return response.data;
    });
};

export const getSingleContentStrategyApi = async (strategyId) => {
  return axios
    .get('/content-strategy/' + strategyId)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const updateContentStrategySaveTopic = async ({ id, group, uid, topicId }) => {
  return axios
    .put(`/content-strategy/${id}/save-topic`, {
      group,
      uid,
      topicId
    })
    .then(({ data: response }) => {
      return response.data;
    });
};

export const deleteContentStrategyApi = async (strategyId) => {
  return axios
    .delete('/content-strategy/' + strategyId)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const createCampaignsFromTAApi = async (body) => {
  return axios
    .post('/campaigns/campaign-from-strategy', body)
    .then((response) => {
      return response.data;
    });
};

// External API

export const searchImagesFromPexelsApi = async ({ query }) => {
  return axios
    .post('/external/pexels/search', {
      query,
    })
    .then((response) => {
      return response.data;
    });
};

export const createSetupIntentApi = async (body) => {
  return axios
    .post('/subscriptions/checkout', body)
    .then((response) => {
      return response.data;
    });
};

export const initSubscription = async (body) => {
  return axios
    .post('/subscriptions/create-setup-intent', body)
    .then((response) => {
      return response.data;
    });
};

export const confirmSubscription = async (body) => {
  return axios
    .post('/subscriptions/confirm-subscription', body)
    .then((response) => {
      return response.data;
    });
};

export const getSubscriptionPlans = async ({ currency }) => {
  return axios.get('/subscriptions/plans', {
    params: {
      currency
    }
  }).then(({ data: response }) => {
    return response.data;
  });
};

export const createCheckoutSessionInr = async (body) => {
  return axios.post('/subscriptions/inr/create-checkout-session', body).then((response) => {
    return response.data;
  });
};

export const createCheckoutSessionUsd = async (body) => {
  return axios.post('/subscriptions/usd/create-checkout-session', body).then((response) => {
    return response.data;
  });
};

export const confirmCheckoutSession = async ({ sessionId }) => {
  return axios.post('/subscriptions/session/' + sessionId).then((response) => {
    return response.data;
  });
};

export const getSubscription = async () => {
  return axios.get('/subscriptions/subscription').then((response) => {
    return response.data;
  });
};

export const getWorkspaceUsage = async () => {
  return axios.get('/subscriptions/usage').then(({ data: response }) => {
    return response.data;
  });
};

export const getNotificationsApi = async () => {
  return axios.get('/notifications').then(({ data: response }) => {
    return response.data;
  });
};

export const exportCampaignApi = async (campaignId) => {
  return axios.get(`/campaigns/${campaignId}/export`).then(({ data: response }) => {
    return response.data;
  });
};

export const importCampaignApi = async (campaignId, body) => {
  return axios.post(`/campaigns/${campaignId}/import`, body).then(({ data: response }) => {
    return response.data;
  });
};

export const deleteNotificationApi = async (id) => {
  return axios.delete('/notifications/' + id).then(({ data: response }) => {
    return response.data;
  });
};

export const getCurrentSubscription = async (id) => {
  return axios.get('/subscriptions/current-subscription').then(({ data: response }) => {
    return response.data;
  });
};

export const getCreditUsagesApi = async () => {
  return axios.get('/credit-usage').then(({ data: response }) => {
    return response.data;
  });
};

export const getCreditUsageApi = async (usageId) => {
  return axios.get('/credit-usage/' + usageId).then(({ data: response }) => {
    return response.data;
  });
};

export const createCreditUsageApi = async (body) => {
  return axios.post('/credit-usage', body).then((response) => {
    return response.data;
  });
};

export const updateCreditUsageApi = async (usageId, body) => {
  return axios
    .put('/credit-usage/' + usageId, body)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const deleteCreditUsageApi = async (usageId) => {
  return axios.delete('/credit-usage/' + usageId).then((response) => {
    return response.data;
  });
};

export const getGenAIRequestsApi = async () => {
  return axios.get('/gen-ai-requests').then(({ data: response }) => {
    return response.data;
  });
};

export const getGenAIRequestApi = async (requestId) => {
  return axios.get('/gen-ai-requests/' + requestId).then(({ data: response }) => {
    return response.data;
  });
};

export const createGenAIRequestApi = async (body) => {
  return axios.post('/gen-ai-requests', body).then((response) => {
    return response.data;
  });
};

export const updateGenAIRequestApi = async (requestId, body) => {
  return axios
    .put('/gen-ai-requests/' + requestId, body)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const deleteGenAIRequestApi = async (requestId) => {
  return axios.delete('/gen-ai-requests/' + requestId).then((response) => {
    return response.data;
  });
};